<template>
  <div>
    <div class="card no-border">
       <a href="#" class="w-inline-block">
        <img v-bind:src="blog.featured_image" width="824" alt class="card-image-head" />
      </a>
      <div class="card-body">
        <h2>{{blog.title}}</h2>
        <a v-on:click="track('image')" v-bind:href="download" class="button w-inline-block post-download" download>
          <div>
            Download
            <i class="fas fa-download"></i>
          </div>
        </a>
           <a
          v-bind:href="blog.link"
          class="button w-inline-block blog-download"
          download
          target="_blank"
          v-on:click="track('edit')"
          v-if="blog.link !== null && blog.link!==''"
        >
          <div>
            Edit
            <i class="fas fa-edit"></i>
          </div>
        </a>
        <a v-on:click="track('blog')" v-bind:href="blog.pdf" target="_blank" class="button w-inline-block blog-download post-download" download>
          <div>
             Blog
            <i class="fas fa-download"></i>
          </div>
        </a>
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  props: {
    blog: Object
  },
  computed: {
    download: function() {
      var media = this.blog.featured_image;
      media = media.substring(26);
      var safeTitle = encodeURI(this.blog.title + ".jpg");
      var url =
        "https://fs.buttercms.com/content=t:attachment,f:%22" +
        safeTitle +
        "%22/" +
        media;
      return url;
    }
  },
  methods:{
        track(edit) {
      var title = "Downloaded Blog Image";
      if (edit == "blog") {
        title = "Downloaded Blog";
      }
      // eslint-disable-next-line no-undef
      mixpanel.track(title, {
        Title: this.blog.title,
      });
    }
  }
};
</script>

<style scoped>
.blog-download
{
  margin-left: 15px;
  background-color: #517FAB;
  border-color: #517FAB;

}
</style>