<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Blogs</h1>
      </div>
    </div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="w-layout-grid grid-thirds card-grid-thirds">
            <BlogPost v-for="blog in blogs" :key="blog.title" :blog="blog" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import ContentService from "@/services/contentService.js";
import BlogPost from "@/components/BlogPost.vue";
import ProfileService from "@/services/profileService.js";
export default {
  name: "home",
  components: {
    Navigation,
    BlogPost
  },
  data() {
    return {
      blogs: []
    };
  },
  created() {
    var userId = this.$auth.user.sub;
    this.$auth.getTokenSilently().then(function(result) {
      vm.bearer = result;
      ProfileService.getUserInfo(userId, result)
        .then(response => {
          console.log(response.data);
          var meta = response.data.user_metadata;
          vm.first = meta.first_name;
          vm.last = meta.last_name;
          vm.brokerage = meta.brokerage;
          vm.paid = response.data.app_metadata.status;
          if (vm.paid == null || vm.paid == "") {
            vm.paid = false;
          }
        })
        .catch(error => {
          console.log("there was an error getting the user metadata", error);
        });
    });
    var vm = this;
    ContentService.getContent("blogs")
      .then(response => {
        vm.blogs = response.data.data.blogs.reverse();
      })
      .catch(error => {
        console.log("There was an error getting blog posts", error);
      });
  }
};
</script>
